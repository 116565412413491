import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)
const meta = {
  charset: 'utf-8',
  name: 'viewport', 
  content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no'
}
const routes = [
  {
    path: '/',
    name: 'home',
    meta:meta,
    // 首页
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    meta:meta,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta:meta,
    // 登录页
    component: () => import( '../views/LoginView.vue')
  },
  {
    // 金融平台
    path: '/platform',
    name: 'platform',
    meta:meta,
    component: () => import( '../views/finance/Platform.vue')
  },
  {
    // 金融产品
    path: '/product',
    name: 'product',
    meta:meta,
    component: () => import( '../views/finance/Product.vue')
  },
  {
    // 金融资讯
    path: '/news',
    name: 'news',
    meta:meta,
    component: () => import( '../views/finance/News.vue')
  },
  {
    // 金融详情
    path: '/financeDetails',
    name: 'financeDetails',
    meta:meta,
    component: () => import( '../views/finance/Details.vue')
  },
  {
    // 供应商
    path: '/distributor',
    name: 'distributor',
    meta:meta,
    component: () => import('../views/distributor/Distributor.vue')
  },
  {
    // 供应商商品列表
    path: '/distributorDetails',
    name: 'distributorDetails',
    meta:meta,
    component: () => import( '../views/distributor/Details.vue')
  },
  {
    // 额度
    path: '/financeLimit',
    name: 'financeLimit',
    meta:meta,
    component: () => import( '../views/finance/Limit.vue')
  },
  {
    // 额度善美
    path: '/financeLimitOther',
    name: 'financeLimitOther',
    meta:meta,
    component: () => import( '../views/finance/LimitOther.vue')
  },
  {
    // 数据图表
    path: '/dataChart',
    name: 'dataChart',
    meta:meta,
    component: () => import( '../views/DataChartView.vue')
  },
  {
    // 权限管理
    path: '/authSetting',
    name: 'authSetting',
    meta:meta,
    component: () => import( '../views/admin/AuthSetting.vue')
  },
  {
    // 平台介绍
    path: '/comIntroduce',
    name: 'comIntroduce',
    meta:meta,
    component: () => import( '../views/ComIntroduce.vue')
  }
]

const router = new VueRouter({
  routes,
})
router.afterEach((to,from,next) => {
    window.scrollTo(0,0);
})



// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}

export default router
