import Vue from 'vue';
import Vuex from 'vuex';
import state from './defaultState';
import mutations from './mutations';

Vue.use(Vuex)

 export default new Vuex.Store({
  state:state,
  mutations
//   // 存储状态
//   state: {
//     user:{
//       name:"",
//       id:""
//     },
//     token:""
//   },
//   // 唯一取值的方法
//   getters: {
//     getUserInfo(state){
//       return state.user;
//     },
//     getToken(state){
//       return state.token
//     }
//   },
//   // 唯一可以设置state值的方法
//   mutations: {
//     updataUser(state,user){
//       state.user = user;
//     },
//     setToken(state,token){
//       state.token = token;
//     }
//   },
//   // 异步调用mutations,commit调用mutations
//   actions: {
//     asyncUpdataUser(context,user){
//       context.commit('updataUser',user);
//     },
//     asyncUpdataToken(context,token){
//       context.commit('setToken',token);
//     }
//   },
//   modules: {
//   }
})
