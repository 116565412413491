<template>
    <div class="top-nav-box">
        <div class="navcontainer">
            <div class="logo-home" @click="routerpush('/')">
                <img src="../assets/logo1.png" class="logo-img" alt="" />
                <div>数字化供应链平台</div>
            </div>
            <div class="top-right-box pc-box">
                <div class="nav-box">
                    <div
                        class="nav-item"
                        @click="routerpush('/', 0)"
                        v-show="isAuthStatus($store.state.UserAuth, 'home_page')"
                    >
                        <span
                            class="title"
                            :style="{ color: type == 0 ? '#0073FE' : '#666666' }"
                            >首页</span
                        >
                    </div>
                    <div
                        class="nav-item"
                        @click="routerpush('/comIntroduce', 3)"
                        v-show="
                            isAuthStatus($store.state.UserAuth, 'platform_intro')
                        "
                    >
                        <span
                            class="title"
                            :style="{ color: type == 3 ? '#0073FE' : '#666666' }"
                            >平台介绍</span
                        >
                    </div>
                    <div
                        class="nav-item"
                        @mouseenter="handmouseenter(2)"
                        @mouseleave="handmouseleave(2)"
                        v-show="isAuthStatus($store.state.UserAuth, 'purchase')"
                    >
                        <span class="title">采购业务</span>
                        <img
                            src="../assets/down02.png"
                            v-show="showIndex != 2"
                            class="down-icon"
                            alt=""
                        />
                        <img
                            src="../assets/up01.png"
                            v-show="showIndex == 2"
                            class="up-icon"
                            alt=""
                        />
                        <div class="nav-child-box" v-show="showIndex == 2">
                            <div class="nav-child-item-box">
                                <div
                                    v-show="
                                        isAuthStatus(
                                            $store.state.UserAuth,
                                            'purchase_go'
                                        )
                                    "
                                    @click="goPurchase"
                                >
                                <!-- goLink(
                                            'https://enterprise.e-cology.com.cn/wui/index.html?v=1703814110382#/?_key=unv7ok'
                                        ) -->
                                    <i
                                        class="el-icon-document"
                                        style="font-size: 1.2em"
                                    ></i
                                    ><span>去采购</span>
                                </div>
                                <div
                                    @click="routerpush('/dataChart')"
                                    v-show="
                                        isAuthStatus(
                                            $store.state.UserAuth,
                                            'purchase_info'
                                        )
                                    "
                                >
                                    <i
                                        class="el-icon-data-line"
                                        style="font-size: 1.2em"
                                    ></i>
                                    <span>数据详情</span>
                                </div>
                                <div
                                    @click="goLink('https://merchant.zgsuanzi.com')"
                                    v-show="
                                        isAuthStatus(
                                            $store.state.UserAuth,
                                            'suan_zi_system'
                                        )
                                    "
                                >
                                    <i
                                        class="el-icon-menu"
                                        style="font-size: 1.2em"
                                    ></i>
                                    <span>蒜子系统</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="nav-item"
                        @click="routerpush('/platform', 1)"
                        v-show="isAuthStatus($store.state.UserAuth, 'financial')"
                    >
                        <span
                            class="title"
                            :style="{ color: type == 1 ? '#0073FE' : '#666666' }"
                            >金融服务</span
                        >
                    </div>
                    <div
                        class="nav-item"
                        @click="routerpush('/distributor', 5)"
                        v-show="isAuthStatus($store.state.UserAuth, 'supplier')"
                    >
                        <span
                            class="title"
                            :style="{ color: type == 5 ? '#0073FE' : '#666666' }"
                            >供应商</span
                        >
                    </div>
                    <div
                        class="nav-item"
                        @click="routerpush('/about', 4)"
                        v-show="
                            isAuthStatus($store.state.UserAuth, 'operation_manual')
                        "
                    >
                        <span
                            class="title"
                            :style="{ color: type == 4 ? '#0073FE' : '#666666' }"
                            >操作手册</span
                        >
                    </div>
                </div>
                <!-- <img src="../assets/lock-icon.png"  @click="routerpush('/authSetting')" class="icon-img" alt="" /> -->
                <img
                    src="../assets/setting-icon.png"
                    class="icon-img"
                    alt=""
                    v-show="getUserKey($store.state.UserInfo, 'is_manager', 1)"
                    @click="goLink('https://shanlvint.sxyjsc.com/shanlv-admin/')"
                />
                <div class="nav-top-right-box">
                    <div
                        class="login-user"
                        v-show="$store.state.UserToken"
                        @mouseleave="handmouseleave(4)"
                    >
                        <img
                            src="../assets/user-icon.png"
                            class="user-img"
                            alt=""
                            @click="authPage"
                        />
                        <div
                            class="red-box"
                            v-if="!is_deal_status($store.state.UserInfo)"
                        ></div>
                        <div class="user-info">
                            <div class="user-name">
                                {{ getUserName($store.state.UserInfo) }}
                            </div>
                            <div
                                class="user-moblie"
                                v-show="sectionName($store.state.Company)"
                                @mouseenter="handmouseenter(4)"
                            >
                                {{ sectionName($store.state.Company) }}
                                <img
                                    src="../assets/down02.png"
                                    class="down-icon"
                                    alt=""
                                    v-show="showIndex != 4"
                                />
                                <img
                                    v-show="showIndex == 4"
                                    src="../assets/up01.png"
                                    class="up-icon"
                                    alt=""
                                />
                            </div>
                        </div>
                        <img
                            src="../assets/out-icon2.png"
                            style="width:1.56em;height: 1.56em;"
                            alt=""
                            @click="loginOutBtn"
                        />
                        <div class="login-out-box" v-show="showIndex == 4">
                            <!-- <div @click="loginOutBtn">
                                <i
                                    class="el-icon-switch-button"
                                    style="font-size: 1.2em"
                                ></i>
                                <span>退出登录</span>
                            </div> -->
                            <div class="cor-item" v-for="(comItem,comIndex) in companyList" :key="comIndex" @click="selectCompany(comItem)">
                                <div class="cor-name"><span :style="{color:selectColor(comItem.company_name)}" >{{comItem.company_name}}</span></div>
                                <div><i class="el-icon-check" style="color:#0073fe;" v-show="selectColor(comItem.company_name)!=''" ></i></div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="login-btn"
                        v-show="!$store.state.UserToken"
                        @click="loginOutBtn"
                    >
                        登录
                    </div>
                </div>
            </div>
            <div class="phone-box" @click="tabListBtn">
                <div class="box">
                    <div :class="['t-mark',tabToastStatus?'t-mark-an':'']"></div>
                    <div :class="['c-mark',tabToastStatus?'c-mark-an':'']"></div>
                    <div :class="['b-mark',tabToastStatus?'b-mark-an':'']"></div>
                </div>
                <!-- <div class="login-user" 
                v-show="sectionName($store.state.Company)"
                @mouseenter="handmouseenter(4)">

                    <img
                        src="../assets/user-icon.png"
                        class="user-img"
                        style="width:3em;height:3em;margin-right:1em"
                        alt=""
                    />
                    <div
                        class="user-moblie"
                    >
                        <img
                            src="../assets/down02.png"
                            class="down-icon"
                            alt=""
                            v-show="showIndex != 4"
                        />
                        <img
                            v-show="showIndex == 4"
                            src="../assets/up01.png"
                            class="up-icon"
                            alt=""
                        />
                    </div>
                </div> -->
            </div>
            <transition name="fade">
                <div class="mask-box" v-show="maskStatus"></div>
            </transition>
            <div class="blank-box" v-show="blankStatus"></div>

            <transition name="fade">
                <div class="phone-mask-box" v-show="tabToastStatus">
                    <div class="phone-menu-box">
                        <!-- <div class="phone-nav-item" v-for="(comItem,comIndex) in companyList" :key="comIndex" @click="selectCompany(comItem)">
                            <div><i class="el-icon-check" style="color:#0073fe;" v-show="selectColor(comItem.company_name)!=''" ></i></div>
                            <div class="cor-name"><span :style="{color:selectColor(comItem.company_name)}" class="title" >{{comItem.company_name}}</span></div>
                        </div> -->

                        <div class="phone-tab-list-box">
                            <div  class="phone-tab-item" v-show="$store.state.UserToken" >
                                <div style="width:100%;display:flex;justify-content:center;align-items:center;">
                                    <img
                                        src="../assets/user-icon.png"
                                        class="user-img"
                                        style="width:3em;height:3em;margin-right:1em"
                                        alt=""
                                    />
                                    <div style="flex:1;text-align:left;margin:0 1em;">
                                        <div  class="user-name">{{ getUserName($store.state.UserInfo) }}</div>
                                        <div class="user-moblie" v-show="sectionName($store.state.Company)">{{ sectionName($store.state.Company) }}</div>
                                    </div>
                                    <i class="el-icon-sort" @click="chnageUserBtn"></i>
                                </div>
                            </div>
                            <div v-show="!changeUserStatus">
                                <div class="phone-tab-item" 
                                :style="{ color: type == 0 ? '#0073FE' : '#666666' }"
                                @click="routerpush('/', 0)"
                                v-show="isAuthStatus($store.state.UserAuth, 'home_page')"
                                >首页</div>
                                <div class="phone-tab-item"
                                :style="{ color: type == 5 ? '#0073FE' : '#666666' }"
                                @click="routerpush('/distributor', 5)"
                                v-show="isAuthStatus($store.state.UserAuth, 'supplier')"
                                >供应商</div>
                                <div class="phone-tab-item"
                                
                                :style="{ color: type == 3 ? '#0073FE' : '#666666' }"
                                @click="routerpush('/comIntroduce', 3)"
                                v-show="isAuthStatus($store.state.UserAuth, 'platform_intro')"
                                >平台介绍</div>
                                <div class="phone-tab-item"
                                :style="{ color: type == 4 ? '#0073FE' : '#666666' }"
                                @click="routerpush('/about', 4)"
                                v-show="isAuthStatus($store.state.UserAuth, 'operation_manual')"
                                >操作手册</div>
                                <div class="phone-tab-item"
                                v-show="isAuthStatus($store.state.UserAuth,'purchase_go')"
                                @click="goPurchase"
                                >去采购</div>
                                <div class="phone-tab-item"
                                @click="routerpush('/dataChart')"
                                v-show="isAuthStatus($store.state.UserAuth,'purchase_info')"
                                >数据详情</div>
                                <div class="phone-tab-item"
                                @click="goLink('https://merchant.zgsuanzi.com')"
                                v-show="isAuthStatus($store.state.UserAuth,'suan_zi_system')"
                                >蒜子系统</div>
                                <div class="phone-tab-item"
                                :style="{ color: type == 1 ? '#0073FE' : '#666666' }"
                                @click="routerpush('/platform', 1)"
                                v-show="isAuthStatus($store.state.UserAuth, 'financial')"
                                >金融服务</div>

                            </div>
                            <div v-show="changeUserStatus">

                                <!-- <div class="phone-tab-item" 
                                :style="{ color: type == 0 ? '#0073FE' : '#666666' }"
                                @click="routerpush('/', 0)"
                                v-show="isAuthStatus($store.state.UserAuth, 'home_page')"
                                >首页</div> -->

                                <div class="cor-item phone-tab-item" v-for="(comItem,comIndex) in companyList" :key="comIndex" @click="selectCompany(comItem)">
                                    <div class="cor-name"><span :style="{color:selectColor(comItem.company_name)}" >{{comItem.company_name}}</span></div>
                                    <div><i class="el-icon-check" style="color:#0073fe;" v-show="selectColor(comItem.company_name)!=''" ></i></div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </transition>
        </div>
        <!-- <div class="phone-box" style="">
            <div class="phone-tab-list-box">
                <div class="phone-tab-item" 
                :style="{ color: type == 0 ? '#0073FE' : '#666666' }"
                @click="routerpush('/', 0)"
                v-show="isAuthStatus($store.state.UserAuth, 'home_page')"
                >首页</div>
                <div class="phone-tab-item"
                :style="{ color: type == 5 ? '#0073FE' : '#666666' }"
                @click="routerpush('/distributor', 5)"
                v-show="isAuthStatus($store.state.UserAuth, 'supplier')"
                >供应商</div>
                <div class="phone-tab-item"
                
                :style="{ color: type == 3 ? '#0073FE' : '#666666' }"
                @click="routerpush('/comIntroduce', 3)"
                v-show="isAuthStatus($store.state.UserAuth, 'platform_intro')"
                >平台介绍</div>
                <div class="phone-tab-item"
                :style="{ color: type == 4 ? '#0073FE' : '#666666' }"
                @click="routerpush('/about', 4)"
                v-show="isAuthStatus($store.state.UserAuth, 'operation_manual')"
                >操作手册</div>
                <div class="phone-tab-item"
                v-show="isAuthStatus($store.state.UserAuth,'purchase_go')"
                @click="goPurchase"
                >去采购</div>
                <div class="phone-tab-item"
                @click="routerpush('/dataChart')"
                v-show="isAuthStatus($store.state.UserAuth,'purchase_info')"
                >数据详情</div>
                <div class="phone-tab-item"
                @click="goLink('https://merchant.zgsuanzi.com')"
                v-show="isAuthStatus($store.state.UserAuth,'suan_zi_system')"
                >蒜子系统</div>
                <div class="phone-tab-item"
                :style="{ color: type == 1 ? '#0073FE' : '#666666' }"
                @click="routerpush('/platform', 1)"
                v-show="isAuthStatus($store.state.UserAuth, 'financial')"
                >金融服务</div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { getRequest, postRequest, phoneRegex } from "../utils/request.js";
import { mapState } from "vuex"
export default {
    name: "navtop",
    components: {},
    data() {
        return {
            type: -1,
            showIndex: -1,
            maskStatus: false,
            isAuthLogin: false,
            blankStatus: false,
            tabToastStatus:false,
            phoneToast:false,
            phoneShowIndex:-1,
            companyList:[],
            changeUserStatus:false,
        };
    },
    computed:{
        ...mapState({
            myStateValue: state => state.UserInfo
        })
    },
    watch: {
        myStateValue(newValue, oldValue) {
            var _this = this;
            let userInfo = newValue?JSON.parse(newValue):'';
            if(userInfo){
                _this.companyList = userInfo.dept_list;
            }
        }
    },
    mounted() {
        var _this = this;
        this.getAuthData();
        let userInfo = _this.$store.state.UserInfo?JSON.parse(_this.$store.state.UserInfo):'';
        if(userInfo){
            _this.companyList = userInfo.dept_list;
        }
    },
    methods: {
        authPage() {
            var _this = this;
            var userInfo = JSON.parse(_this.$store.state.UserInfo);
            if (userInfo.is_sub_admin == 1) {
                _this.routerpush("/authSetting");
            }
        },
        routerpush(to, type) {
            let _this = this;
            _this.showIndex = -1;
            _this.type = type;
            _this.$router.push(to);
            if(_this.tabToastStatus){
                _this.tabListBtn();
            }
            // _this.tabListBtn();
        },
        handmouseenter(index) {
            let _this = this;
            _this.showIndex = index;
            _this.maskStatus = true;
        },
        handmouseleave(index) {
            let _this = this;
            _this.showIndex = -1;
            _this.maskStatus = false;
        },


        goLink(url) {
            window.open(url);
        },
        loginOutBtn() {
            var _this = this;
            _this.showIndex = -1;
            _this.$store.commit("LOGIN_OUT");
            _this.$store.commit("UPDATA_USER", "");
            _this.$store.commit("ISLOGINPAGE", 1);
            _this.$store.commit("UPDATA_AUTH", "");
            _this.$store.commit("UPDATA_COMPANY",'');
            _this.blankStatus = true;
            _this.routerpush("/login");
            setTimeout(function () {
                _this.blankStatus = false;
            }, 2000);
        },
        getUserName(user) {
            var nickname = "";
            if (user && user != "undefined" && user != "null") {
                var userinfo = JSON.parse(user);
                nickname = userinfo.nickname
                    ? userinfo.nickname
                    : userinfo.mobile;
                if (!userinfo.nickname) {
                    var start = userinfo.mobile.substring(0, 3);
                    var end = userinfo.mobile.slice(-4);
                    nickname = start + "****" + end;
                }
            }
            return nickname;
        },
        sectionName(user) {
            var star = "";
            var name = "";
            if (user && user != "undefined" && user != "null") {
                var userinfo = JSON.parse(user);
                name = userinfo.company_name ? userinfo.company_name : "";
                if (name && name.length > 5) {
                    star = star + "***";
                }
                var start = name.substring(0, 3);
                var end = name.slice(-2);
                name = start + star + end;
            }
            return name;
        },
        is_deal_status(user) {
            var deal_status = 0;
            if (user && user != "undefined" && user != "null") {
                var userinfo = JSON.parse(user);
                deal_status = userinfo.deal_status ? userinfo.deal_status : "";
            }
            return deal_status == 0&&user&&user.is_sub_admin==1 ? false : true;
        },
        isAuthStatus(auth, key) {
            var authStatus = false;
            if (auth && auth != "undefined" && auth != "null") {
                var authData = JSON.parse(auth);
                authStatus = authData[key] == 1 ? true : false;
            }
            return authStatus;
        },

        getAuthData() {
            let _this = this;
            var mid = "";
            if (
                _this.$store.state.UserInfo &&
                _this.$store.state.UserInfo != "undefined" &&
                _this.$store.state.UserInfo != "null"
            ) {
                var userinfo = JSON.parse(_this.$store.state.UserInfo);
                mid = userinfo.mid;
            }
            let params = {
                mid: mid,
            };
            let url = "/api/index/getHomeMenuAuth";
            postRequest(url, params, function (e) {
                // console.log("权限111111");
                // console.log(e);
                if (e.status == 200) {
                    _this.$store.commit("UPDATA_AUTH", JSON.stringify(e.data));
                }
            });
        },
        getUserKey(user, key, trueVal) {
            // key--要查找的属性名
            // trueVal--与之相等的值则返回true  不传则默认判断该属性有无值 有值为ture 反之false
            var status = false;
            if (user && user != "undefined" && user != "null") {
                var userinfo = JSON.parse(user);
                if (trueVal) {
                    status = userinfo[key] == trueVal ? true : false;
                } else {
                    status = userinfo[key] ? true : false;
                }
            }
            return status;
        },
        tabListBtn(){
            var _this = this;
            _this.tabToastStatus = !_this.tabToastStatus;
        },
        selectIndex(type){
            var _this = this;
            if(type==_this.phoneShowIndex){
                _this.phoneShowIndex = -1
            }else{
                _this.phoneShowIndex = type;
            }
        },
        goPurchase(){
            
            let _this = this;
            let params = {};
            let url = "/api/caiGou/getToken";
            postRequest(url, params, function (e) {
                if (e.status == 200) {
                    _this.goLink(e.url)
                    if(_this.tabToastStatus){
                        _this.tabListBtn();
                    }
                }else{
                    _this.$message({
                        message: e.msg,
                        type: "warning",
                    });
                }
            });
        },
        selectCompany(e){
            var _this = this;
            _this.handmouseleave(4);
            _this.getAuthData();
            _this.$store.commit("UPDATA_COMPANY", JSON.stringify(e));
            _this.routerpush("/");
            _this.tabToastStatus = false;
        },
        selectColor(text){
            var _this = this;
            var company = JSON.parse(_this.$store.state.Company);
            var color = '';
            if(company&&company.company_name==text){
                color = '#0073fe'
            }
            return color;
        },
        chnageUserBtn(){
            var _this =this;
            _this.changeUserStatus = !_this.changeUserStatus;
        },
    },
};
</script>

<style lang="less" scoped>
.top-nav-box{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0px;
    background-color: #ffffff;
    z-index: 99;
}
.navcontainer {
    width: 100%;
    height: 7em;
    // font-size: 10px;
    // min-width: 1500px;
    padding: 0px 2%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.logo-home {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    div {
        min-width: 9em;
        font-size: 1.6em;
        font-weight: bold;
        margin-left: 1.25em;
    }
}
.logo-img {
    width: 14em;
    height: 2.8em;
}
.nav-box {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: 2em;
}
.nav-item {
    flex: 1;
    min-width: 8em;
    max-width: 10em;
    height: 7em;
    text-align: center;
    display: inline-flex;
    border-bottom: 0.2em solid #ffffff;
    position: relative;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span {
        font-size: 1.1em;
        color: #666666;
    }
}
.down-icon {
    width: 0.7em;
    height: 0.36em;
    margin-left: .2em;
}
.up-icon {
    width: 0.7em;
    height: 0.36em;
    margin-left: .2em;
}
.nav-item:hover {
    border-bottom: 0.2em solid #0073fe;
}
.nav-item:hover .title {
    color: #0073fe;
    cursor: pointer;
}
.nav-item:hover .up-icon {
    display: inline-block;
}
.nav-item:hover .down-icon {
    display: none;
}
.nav-item:hover .nav-child-box {
    display: flex;
}
.login-user {
    height: 7em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}
.red-box {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: red;
    position: absolute;
    top: 2em;
    left: 2.2em;
}
.user-img {
    width: 2.2em;
    height: 2.2em;
    border-radius: 50%;
}
.user-info {
    width: 9em;
    margin: 0 1em;
    text-align: left;
}
.user-name {
    font-size: 1em;
    color: #0073fe;
}
.user-moblie {
    font-size: 1em;
    color: #999999;
}
.mask-box {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 7em;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav-child-box {
    width: 200%;
    max-width: 12em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 7em;
    left: 0;
    z-index: 99;
    background-color: #ffffff;
    padding: 3em 0em;
}
.nav-child-item-box {
    width: 100%;
    // margin: 0em 4em;
    .nav-child-item-title {
        width: 100%;
        font-size: 2em;
        font-weight: bold;
        text-align: center;
        line-height: 3em;
        border-bottom: 1px solid #00000012;
    }
    div {
        width: 100%;
        text-align: center;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0em 2em;
        box-sizing: border-box;
        span {
            flex: 1;
            text-align: center;
            line-height: 4em;
            font-size: 1em;
        }
    }
    div:hover {
        color: #0073fe;
    }
    div:hover span {
        color: #0073fe;
    }
}
.login-btn {
    width: 6.4em;
    background: linear-gradient(150deg, #0166f3 0%, #06b3ff 100%);
    border-radius: 1.6em;
    text-align: center;
    font-size: 1em;
    color: #ffffff;
    line-height: 2.2em;
    cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.nav-top-right-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: 1em;
}
.tips-box {
    display: flex;
    min-width: 15em;
    height: 10em;
    text-align: right;
    position: relative;
    border-bottom: 0.25em solid #ffffff;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
}
.tips-box:hover {
    border-bottom: 0.25em solid #0073fe;
}
.tips-box:hover .nav-child-box {
    display: flex;
}
.nav-top-right-line {
    width: 1px;
    height: 2.4em;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0px 2em;
}
.taost-box {
    width: 36em;
    height: 22em;
    background-color: #ffffff;
    border-radius: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.toast-title {
    width: 100%;
    font-size: 2em;
    color: #0073fe;
    text-align: center;
    font-weight: bold;
}
.toast-content {
    width: 100%;
    font-size: 1.8em;
    color: #666666;
    text-align: center;
    padding: 1.2em;
    box-sizing: border-box;
    font-weight: bold;
}
.top-right-box {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.icon-img {
    width: 2.2em;
    height: 2.2em;
    margin: 0 0.6em;
    cursor: pointer;
}
// @media only screen and (max-width: 1200px) {
//     .logo-home {
//         div {
//             display: none;
//         }
//     }
// }
.login-out-box {
    width: 18em;
    max-height: 20em;
    position: absolute;
    top: 7em;
    right: 0;
    z-index: 99;
    background-color: #ffffff;
    padding: 2em 0em;
    cursor: pointer;
    text-align: left;
    span {
        font-size: 1em;
        line-height: 2em;
        color: #666666;
    }
    .cor-item{
        padding:0 1em;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .cor-name{
        width: 15em;
        text-align: left;
    }
    div:hover {
        color: #0073fe;
    }
    div:hover span {
        color: #0073fe;
        cursor: pointer;
    }
    i{
        font-size: 1em;
    }
}
.blank-box {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #ffffff;
    z-index: 99;
    top: 0;
    left: 0;
}
.phone-box {
    display: none;
}
/* 隐藏垂直滚动条 */
.login-out-box::-webkit-scrollbar {
  width: 0;
}
 
/* 隐藏水平滚动条 */
.login-out-box::-webkit-scrollbar {
  height: 0;
}
@media screen and (max-width: 850px) {
    .pc-box {
        display: none;
    }
    .phone-box {
        display: block;
    }
    .phone-tab-list-box{
        width:100%;
        padding:1em 0em;
        background-color: #ffffff;
        /* display: grid;*/
        /* grid-gap: 2em;  每个网格之间的间距 */
         /* justify-content: space-evenly; 均匀平铺 */
         /* grid-template-columns: repeat(auto-fill, 20%);*/
    }
    .phone-tab-item{
       /*  display: inline-block;*/
        font-size: 1.5em;
        padding: 1em;
        border-bottom: 1px solid #f2f2f2;
        cursor: pointer;
    }
    .phone-tab-item:hover{
        color: #0073fe;
    }
    .phone-tab-item:last-child{
        border-bottom:none;
    }
    .phone-tab-item-active{
        color: #0073fe;
    }
    .phone-tab-list-box .phone-tab-item:last-child{
        border-bottom: none;
    }
    /* 添加动画名称和持续时间 */
    .box {
        width: 2.8em;
        height: 2.5em;
        position: relative;
    }
    .c-mark {
        width: 2.8em;
        height: .5em;
        background: #000;
        /* animation: x-mark-animation 0.5s infinite alternate; */
        position: absolute;
        top: .95em;
        animation: all 0.3s forwards;
    }

    /* 创建动画 */
    @keyframes x-mark-animation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(48deg);
        }
    }
    @keyframes t-animation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(-48deg);
        }
    }
    .t-mark {
        width: 2.8em;
        height: .5em;
        background: #000;
        position: absolute;
        top: 0px;
        animation: all 0.3s forwards;
    }
    .b-mark {
        width: 2.8em;
        height: .5em;
        background: #000;
        position: absolute;
        bottom: 0px;
        animation: all 0.3s forwards;
    }
    .c-mark-an {
        background: rgba(0, 0, 0, 0);
        animation: all 0.3s forwards;
    }
    .t-mark-an {
        top: .95em;
        animation: x-mark-animation 0.3s forwards;
    }
    .b-mark-an {
        top: .95em;
        animation: t-animation 0.3s forwards;
    }
    .phone-mask-box{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 7em;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
    }
    .phone-menu-box{
        width: 65%;
        background-color: #ffffff;
        border-radius: .8em;
        box-shadow: 0px 4px 10px rgba(0,0,0,.3);
    }
    .phone-nav-item{
        width: 100%;
        border-top: 1px solid #efefef;
        text-align: left;
        min-height: 6em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0em 2em;
        box-sizing: border-box;
        .title{
            font-size: 1.5em;
        }
    }
    .phone-nav-child-box{
        
    }
    .phone-nav-child-item-box{
        width: 100%;
        div{
            width: 100%;
            height: 6em;
            border-top: 1px solid #efefef;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding:0em 5em;
            box-sizing: border-box;
            span {
                line-height: 4em;
                font-size: 1.5em;
            }
        }
    }
}
</style>