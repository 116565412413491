<template>
    <div class="swiper-container swiper-demo">
        <div class="swiper-wrapper">
            <slot></slot>
        </div>
        <div class="swiper-button-next" v-show="isNav"></div>
        <div class="swiper-button-prev" v-show="isNav"></div>
        <div class="swiper-pagination" v-show="isPagination"></div>
        <!-- 如果需要滚动条 -->
        <div class="swiper-scrollbar" v-show="isScrollbar"></div>
    </div>
</template>
<script>
import "swiper/css/swiper.css";
import Swiper from "swiper";

export default {
    components: {
        Swiper,
    },
    props: {
        isNav: {
            type: Boolean,
            value: false,
        },
        isPagination: {
            type: Boolean,
            value: false,
        },
        isScrollbar: {
            type: Boolean,
            value: false,
        },
        autoplay: {
            type: Boolean,
            value: true,
        },
        loop: {
            type: Boolean,
            value: true,
        },
        keyboard: {
            type: Boolean,
            value: false,
        },
    },
    data() {
        return {
            bannerList: [
                {
                    imgUrl: "../assets/1-1.png",
                },
                {
                    imgUrl: "../assets/1-2.png",
                },
            ],
        };
    },
    mounted() {
        let _this = this;
        // 下面是普通swiper模板
        const swiper = new Swiper(".swiper-demo", {
            loop: true,
            keyboard: _this.keyboard,
            autoplay: {
                delay: 3000,
            },
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
            },
        });
    },
};
</script>
<style scoped>
</style>