export default{
    LOGIN_IN(state,token){
        state.UserToken = token;
    },
    LOGIN_OUT(state){
        state.UserToken = '';
    },
    UPDATA_USER(state,user){
        state.UserInfo = user;
    },
    UPDATA_AUTH(state,auth){
        state.UserAuth = auth;
    },
    UPDATA_COMPANY(state,company){
        state.Company = company;
    }
}