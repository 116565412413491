<template>
    <div id="app">
        <NavTop></NavTop>
        <div class="top-blank-box"></div>
        <router-view></router-view>
        <Footer></Footer>
    </div>
</template>

<script>
import NavTop from "./components/NavTop";
import Footer from "./components/Footer";
// console.log = ()=> {};
export default {
    name: "App",
    components: {
        NavTop,
        Footer,
    },
    data() {
        return {
            width:0
        }
    },
    mounted() {

    },
};
</script>

<style lang="less">
* {
    padding: 0;
    margin: 0;
}
html {
    // min-width: 1500px;
    font-size: .8vw;
    // font-size: 16px;
    // font-size: 16px;
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.visit-btn {
    min-width: 6.25em;
    padding: 0.5em 2em;
    display: inline-block;
    background: linear-gradient(150deg, #0166f3 0%, #06b3ff 100%);
    border-radius: 1.19em;
    font-size: 1em;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
}
.main-box {
    width: 91.25em;
    margin: 0 auto;
}
input:focus {
    outline: none;
}
.top-blank-box{
    height: 7em;
}

@media screen and (max-width: 850px) {
    #app {
        // min-width: 1500px;
        // font-size: 0.8vw;
        font-size: 16px;
        // font-family: Avenir, Helvetica, Arial, sans-serif;
        // -webkit-font-smoothing: antialiased;
        // -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    .main-box {
        width: 100%;
    }
}
@media screen and (max-width: 500px) {
    #app {
        // min-width: 1500px;
        // font-size: 0.8vw;
        font-size: 8px;
        // font-family: Avenir, Helvetica, Arial, sans-serif;
        // -webkit-font-smoothing: antialiased;
        // -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }
}
</style>
