export default{
    get UserToken(){
        return localStorage.getItem('token')
    },
    set UserToken(value){
        localStorage.setItem('token',value)
    },
    get UserInfo(){
        return localStorage.getItem('user')
    },
    set UserInfo(value){
        localStorage.setItem('user',value)
    },
    get UserAuth(){
        return localStorage.getItem('auth')
    },
    set UserAuth(value){
        localStorage.setItem('auth',value)
    },
    get Company(){
        return localStorage.getItem('company')
    },
    set Company(value){
        localStorage.setItem('company',value)
    },
    

}