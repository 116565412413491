import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueLazyload from 'vue-lazyload';

Vue.use(ElementUI);
Vue.use(VueLazyload, {
  // 设置懒加载时的占位图
  loading: require('./assets/banner/pl-bg.png'),
  // 设置图片加载失败时的占位图
  error: require('./assets/banner/pl-bg.png')
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
