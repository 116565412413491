<template>
    <el-carousel
        :interval="3000"
        height="34.375em"
        style="width: 100%"
        class="carouselBox"
    >
        <el-carousel-item v-for="(item, index) in list" :key="index">
            <div class="item-swiper-box" style="position: relative">
                <!-- <el-image
                    :src="item.pic"
                    style="width: 100%; height: 34.375em"
                    fit="cover"
                    lazy
                >
                </el-image> -->
                <img v-lazy="item.pic" class="banner-img" alt="" />
                <div class="swiper-info-text">
                    <div class="swiper-item-title">
                        {{ item.title ? item.title : "陕旅集团综合管理工具" }}
                    </div>
                    <div class="swiper-item-text">
                        {{
                            item.intro
                                ? item.intro
                                : ""
                        }}
                    </div>
                    <div style="margin-top: 4em" v-show="item.url">
                        <div class="visit-btn" @click="clickDetails(item)">了解详情</div>
                    </div>
                </div>
            </div>
        </el-carousel-item>
    </el-carousel>
</template>
  <script async>
import { getRequest, postRequest, phoneRegex } from "../utils/request.js";

export default {
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        typeVal: {
            type: Number, //类型  首页|1,金融平台|2,金融资讯|3,金融产品|4,供应商|5,平台介绍|6,操作手册|7
            value: 1,
        },
    },
    data() {
        return {
            isTwo: false,
            plImg:require('@/assets/banner/pl-bg.png')
        };
    },
    mounted() {
        var _this = this;
        _this.getSwiperImg();
    },
    methods: {
        getSwiperImg() {
            let _this = this;
            let params = {
                type: _this.typeVal,
            };
            let url = "/api/index/getSlideList";
            postRequest(url, params, function (e) {
                if (e.status == 200) {
                    if (e.data.length == 2) {
                        _this.isTwo = true;
                        _this.list = e.data.concat(e.data);
                    } else {
                        _this.isTwo = false;
                        _this.list = e.data;
                    }
                }
            });
        },
        clickDetails(info) {
            window.open(info.url)
        }
    },
};
</script>
<style  lang="less" scoped>
.swiper-info-text {
    width: 100%;
    height: 34.375em;
    position: absolute;
    top: 0px;
    left: 0px;
    text-align: left;
    font-family: serif;
    padding: 8% 14%;
    box-sizing: border-box;
    background: no-repeat;
}
.swiper-item-title {
    font-size: 4.3em;
    color: #ffffff;
}
.swiper-item-text {
    width: 47em;
    font-size: 1em;
    color: #ffffff;
    line-height: 2em;
    margin-top: 1.25em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}
.carouselBox {
    //将复制出来的数据的下标隐藏
    :deep(.el-carousel__indicators) {
        & > li:nth-child(3),
        & > li:nth-child(4) {
            display: none;
        }
    }
}
.banner-img{
    width: 100%;
    min-height:34.375em; 
    height: auto;
    object-fit:cover;
}
//调整走马灯箭头-左箭头
::v-deep .el-carousel__arrow--left{
  font-size: 30px;
  color: #02E9FF;
}

//右箭头
::v-deep .el-carousel__arrow--right{
  font-size: 30px;
}

@media screen and (max-width: 850px) {

    .swiper-item-text{
        width: auto;
    }
}
</style>