import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=dcc24f9a&scoped=true"
import script from "./Banner.vue?vue&type=script&async=true&lang=js"
export * from "./Banner.vue?vue&type=script&async=true&lang=js"
import style0 from "./Banner.vue?vue&type=style&index=0&id=dcc24f9a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcc24f9a",
  null
  
)

export default component.exports