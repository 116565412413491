<template>
    <div class="footer-main">
        <div class="left">
            <div>法律声明</div>|
            <div>联系我们</div>|
            <div>友情链接</div>|
            <div>使用帮助</div>|
            <div>隐私安全</div>
        </div>
        <div class="blank-box" style="flex: 1;"></div>
        <div class="right">
            <div>
                <div>陕ICP备16017695号-1 版权所有 © 2008-2022</div>
                <div>陕西旅游集团有限公司</div>
            </div>
            <img src="../assets/qrcode.png" alt="">
        </div>
    </div>
</template>

<script>


export default {
  name: 'mainfooter',
  components: {
  }
}
</script>

<style lang="less" scoped>
    .footer-main{
        width: 100%;
        background-color: #F5F5F5;
        padding: 10em 20%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border-top: 1px solid rgba(0,0,0,0.12);
    }
    .left{
        div{
            font-size: .875em;
            margin: .68em;
            display: inline-block;
            cursor: pointer;
        }
    }
    .right{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        div{
            text-align: right;
            font-size: .875em;
            line-height: 1.5em;
            color: #000000;
        }
        img{
            width: 6.5em;
            height: 6.5em;
            margin-left: 3em;
        }
    }

@media screen and (max-width: 850px) {
    .footer-main{
        flex-direction: column;
    }
    .blank-box{
        display: none;
    }
}

</style>