import axios from "axios";
import store from "../store/index"
const instance = axios.create({
    // baseURL: 'https://a.liuyunnet.com',
    baseURL: 'https://shanlvint.sxyjsc.com',
    timeout: 5000
})
// 请求拦截
// 所有的网络请求都会先走这个方法,可以为请求添加自定义内容
instance.interceptors.request.use(
    function (config) {
        let token = store.state.UserToken;
        let companyId = "";
        if(store.state.Company&&store.state.Company!=undefined&&store.state.Company!=""&&store.state.Company!="undefined"){
            var companyInfo = JSON.parse(store.state.Company);
            companyId = companyInfo.company_id?companyInfo.company_id:''
        }
        config.headers.Authorization = token;
        config.headers.CompanyId = companyId;
        return config
    },
    function (err) {
        return Promise.reject(err)
    }
)

// 响应拦截
// 所有的请求返回数据之后都会执行的方法
// 此处可以根据服务器的返回状态码做相应的处理
// 404,401,50x
instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (err) {
        return Promise.reject(err);
    }
)

export function getRequest(url, params, callback) {
    instance.get(url, { params }).then(function (res) {
        return typeof callback == "function" && callback(res.data)
    });
}

export function postRequest(url, data, callback) {
    instance.post(url, data).then(function (res) {
        return typeof callback == "function" && callback(res.data)
    })
}

export function del(url) {
    return instance.delete(url)
}

export function put(url, data) {
    return instance.put(url, data)
}

// 手机号正则验证
export function phoneRegex(mobile) {
    const phoneRegex = /^1[3456789]\d{9}$/;
    return phoneRegex.test(mobile)
}

/**
 * 将时间戳转换为  例: 年-月-日 时:分:秒
 * 
 * type--不传默认为：时间戳*1000
 * type==1 --- 秒计算
 */
export function formattedDate(value,type) {
    const timestamp = type==1?value:value*1000;
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    const timeData = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    return timeData;
}